import React from "react";
import ModalTiny from "react-bootstrap/Modal"
import { Link } from "@StarberryUtils";
import { useLocation } from "@reach/router";
import { CloseIcon } from "../Icons/Icons";
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton
} from "react-share";

import {
	FacebookIcon,
	LinkedinIcon,
	TwitterIcon,
	WhatsappIcon
} from "react-share";
import Dacha from "../../images/dacha.png"
import "../Sidebar/StaticPageSidebar.scss";
import CareerForm from "../forms/career-form";

const CareerContentSidebar = (props) => {

    const location = useLocation();

	// Share icons
	const shareurl = typeof window !== 'undefined' ? window.location.href : ''
	const [Shareicons, setShareicons] = React.useState(false);
	const [ShareiconsMobile, setShareiconsMobile] = React.useState(false);

	const [modalApplyformOpen, setApplyformOpen] = React.useState(false);

	const openShareicons = () => {
		setShareicons(true);
	}
	const closeShareicons = () => {
		setShareicons(false);
	}	
	const openApplyformModal = () => {
		setApplyformOpen(true);
	}
	const closeApplyformModal = () => {
		setApplyformOpen(false);
	}


	const trackerShare = (event) => {
		var shareURL = typeof window !== 'undefined' ? window.location.href : ''
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': 'Share - social icons',
			'formType': event + ' - ' + shareURL,
			'formId': 'Share',
			'formName': 'Share',
			'formLabel': 'Share'
		});
	}

	return (
		<>
		<div className={`career-page-sidebar-widget static-page-sidebar-widget static-sticky-sidebar-widget`}>
			<div className="sidebar-top">
				<h5 className="text-lg-bold-32-18">
					Interested?
				</h5>
				<div className="text-sm sidebar-intro-content">
					<p>Take the next step in your career. Apply now to grow, learn, and thrive with us!</p>
				</div>
			</div>
            
				<div className="sidebar-contact-info">
					<button className="btn btn-primary" onClick={openApplyformModal} >
						Apply for this Job
					</button>
					<div className="share-info-wrap">
							<a className="share-btn btn btn-outline" href={"javascript:;"} onClick={openShareicons} onMouseLeave={closeShareicons}>	
							<span>Share</span>								
							</a>			
							{Shareicons &&
								<div onMouseEnter={openShareicons} onMouseLeave={closeShareicons} className="share-social-icons">
									<FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
										<FacebookIcon size={32} round={false} borderRadius={`10`} />
									</FacebookShareButton>
									<TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
										<TwitterIcon size={32} round={false} borderRadius={`10`} />
									</TwitterShareButton>
									<LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
										<LinkedinIcon size={32} round={false} borderRadius={`10`} />
									</LinkedinShareButton>
									<WhatsappShareButton onClick={() => trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
										<WhatsappIcon size={32} round={false} borderRadius={`10`} />
									</WhatsappShareButton>	
								</div>
							}	
					</div>
				</div>			
				<div className="agent-info">
					<div className="agent-pic">
						<img src={props.people?.Tile_Image?.url || Dacha} alt="Agent" />
					</div>
					<div className="agent-data">
						<h6 className="text-sm-bold"><Link href={`/about-dacha/our-team/${props.people?.URL}`}>{props.people?.Name}</Link></h6>
						<p className="text-xxs">{props.people?.Designation}</p>
					</div>
				</div>			
		</div>
		<ModalTiny show={modalApplyformOpen} onHide={closeApplyformModal} className="modal-form-wrapper people-details-form-modal">
          <ModalTiny.Header>
          <div class="modal-close-btn" onClick={closeApplyformModal}><CloseIcon /></div>
            <ModalTiny.Title><h4>Send your CV</h4></ModalTiny.Title>
          </ModalTiny.Header>
          <ModalTiny.Body>
             <CareerForm />
          </ModalTiny.Body>
        </ModalTiny>
	   </>
	);
};

export default CareerContentSidebar;