import React, { useRef, useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import FileField from './elements/file'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import bsCustomFileInput from 'bs-custom-file-input'
import $ from 'jquery'


import "./assets/styles/Form.scss";

import axios from "axios"
import * as qs from "query-string"

function ValuationForm(props) {

  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");
  const [file, setFile] = useState(""); // storing the uploaded file
  // storing the recived file from backend
  const [data, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();
  const fields = ([
    {
      element: "config",
      formname: "Mortgage Consulting",
      error_text: "Highlighted fields are required",
      success_text: "Thank you for your interest. We will be in touch with you shortly.",
      email_temp_user: "mortgage_consulting_user",
      email_temp_admin: "mortgage_consulting_admin",
      email_server_func: "mortgageConsulting",
      event_tracking: "mortgageConsulting",
      page_url: "/mortgage-consulting"
    },
    {
      grpmd: "12",
      placeholder: "Your Name *",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      label: "Name",
      labelClass: "text-xs-bold"
    },
    {
      grpmd: "12",
      placeholder: "Your Email Address *",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      label: "Email Address",
      labelClass: "text-xs-bold"
    },
    {
      grpmd: "12",
      placeholder: "Your Phone Number *",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*$",
      fieldClass: "",
      label: "Phone Number",
      labelClass: "text-xs-bold"
    },   
    {
      grpmd: "12",
      placeholder: "Interested Communities*",
      name: "interested_communities",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      label: "Interested Communities",
      labelClass: "text-xs-bold"
    },
    {
      grpmd: "12",
      placeholder: "Leave your message ",
      name: "message",
      required: true,
      element: "textarea",
      class: "cover-letter",
      rows: "3",
      label: "Message",
      labelClass: "text-xs-bold"
    },
    {
      name: "Submit Details",
      type: "submit",
      element: "button",
      value: "Submit",
      step: "col-md-4 submit-btn-wrap",
      class: "btn btn-primary",
      formclass: "banner_submit_btn form-submit-btn"
    },    
    {
      text: 'By clicking Submit, you agree to our <a class="content-link text-xxs">Terms & Conditions</a> and <a href="/privacy-policy/" class="content-link text-xxs">Privacy Policy</a>',
      element: "html",
      class: "frmbtmtext text-xxs col-12"
    },
    {
      element: "captcha",
      class: "captcha-class",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  const handleAttachment = event => {
    const fileItem = event.target.files[0]; // accesing file

    var d = document.getElementById('custom-file');
    //alert(d.value);
    $('.form-control-browsefile .custom-file-label').html(d.value.replace(/C:\\fakepath\\/, ''));

    setFile(fileItem); // storing file 
  };


  useEffect(() => {

    bsCustomFileInput.init()
    if (token !== '') {

      const processFromData = async () => {


        formvalues['g-recaptcha-response'] = token;

        let formData = new FormData();
        if (formvalues?.files) {
          formData.append('files.attachment', formvalues.files, formvalues.files.name); // appending file
        }
        formvalues['extra'] = JSON.stringify({
          formname: formvalues.formname,
          interested_communities: formvalues.interested_communities,
        });

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {

          if (apiRes?.attachment?.url) {
            // If image from externall url, use that
            if (apiRes.attachment.url.match(/(http|https):\/\//g)) {
              formvalues['file'] = `${apiRes.attachment.url}`;
              formvalues['cvname'] = `${apiRes.attachment.url}`;
            } else { // or we consider it is loading from api url
              formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment.url}`;
              formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment.url}`;
            }
          }
          window.grecaptcha.reset()

          // lets send mail
          formvalues['email_subject_user'] = 'Property Valuation';
          formvalues['email_subject_admin'] = 'Property Valuation';
          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })


        });


        const axiosOptions_netlify = {
          url: fields[0].page_url,
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify(formvalues),
        }

        // axios(axiosOptions_netlify)
        //   .then(response => {
        //     console.log('data stored')
        //   })
        //   .catch(err =>
        //     console.log(err)
        //   );
        // const url = typeof window !== 'undefined' ? window.location.href : ''
        // if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
        //   fields[0].formname = 'Get mortgage help';
        // }
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-' + fields[0].event_tracking,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });

        setShowerror(false);
        setThankyou(true);
        setTimeout(() => {
          $(".alert-success").show().delay(4000).fadeOut();
        }, 500)
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    if ((file == '') || (file == null)) {
      $('.form-control-browsefile').addClass('fileerror');
    } else {
      $('.form-control-browsefile').removeClass('fileerror');
    }

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {
      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;


      json['files'] = file;
      setFile(file);
      setFormvalues(json);
      setToken("test");
      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      setStartDate();
      setStartTime();

    }
  };

  return (
    <div className={props.classone ? props.classone : 'book-val-form'}>
      <div ref={myRef} />

      {showerror &&
        <div className="row">
          <div className="col-12 alert-error">
            <p>{fields[0].error_text}</p>
          </div>
        </div>
      }

      {showthankyou &&
        <div className="row">
          <div className="col-12 alert-success">
            <p>{fields[0].success_text}</p>
          </div>
        </div>
      }


      <Form className="form-wrap book-valuation-form" name={fields[0].formname}   method="post" noValidate validated={validated} onSubmit={handleSubmit} >
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="job_role" value={props.title} />
        <input type="hidden" name="bot-field" />
        <div className="row">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  startDate={field.type === 'datePicker' ? startDate : ''}
                  setStartDate={field.type === 'datePicker' ? setStartDate : ''}
                  startTime={field.type === 'timePicker' ? startTime : ''}
                  setStartTime={field.type === 'timePicker' ? setStartTime : ''}
                  label={field.label}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  step={field.step}
                />
              );
            }
            if ("file" === field.element) {
              return (
                <FileField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  label={field.label}
                  type={field.type}
                  fieldClass={field.fieldClass}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  key={`${field.element}~${index}`}
                  accept={field.accept}
                  handlechange={handleAttachment}
                  required={field.required}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  id={field.id}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  step={field.step}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  label={field.label}
                  handlechange={handlechange}
                  grpmd={field.grpmd}
                  labelClass={field.labelClass}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  step={field.step}
                  formclass={field.formclass}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
          }
        </div>
      </Form>
    </div>
  );
}


const ValuationFormPage = (props) => (
  <ValuationForm classone={props.classone} title={props.title} />
)

export default ValuationFormPage