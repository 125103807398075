import React, { useState, useEffect, PropTypes } from "react"
import { Link } from "@StarberryUtils";
import { graphql } from 'gatsby'
import SEO from "../components/seo"

import Header from "../components/Header/Header";
import Breadcrumb from "../components/Home/Breadcrumb/Breadcrumb";
import Footer from "../components/Footer/Footer";
import StickyFooter from "../components/Footer/StickyFooter";
import NewsLetter from "../components/Home/NewsLetter/NewsLetter";
import Modules from '../components/modules'
import useDeviceMedia from "../components/utils/useDeviceMedia";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/bootstrap-overrides.scss";
import "../scss/grunticon.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import "./scss/default-template.scss";
import CareerContent from "../components/CareerContent/CareerContent";
import FooterBlock from "../components/Home/ContactUs/Contact";


const CareerDetails = (props) => {
    const { isCustomMin } = useDeviceMedia({ min: "768px" });
    
  
    const GQLPage = props.data.glstrapi?.career;
    const wrapClass = GQLPage?.JobTitle ? `career-details-template page-${GQLPage?.JobTitle.toLowerCase().replace(/\s+/g, '-')}-wrap` : '';
  
    var metaTitle = GQLPage.Meta_Title;
    var metaDesc = GQLPage.Meta_Description;
    
    return (
      <div className={`${wrapClass}`}>
        <SEO title={metaTitle} description={metaDesc} location={GQLPage?.Location} />
        <div className={`career-details-wrap`}>
             <Header fixed={true}  /> 
             <Breadcrumb data={{
                Label: "Careers",
                Main_Parent: { Label: 'About Us', id: '609a84570336654c2f3c0289', URL: 'about-dacha' },
                Sub_Parent: null
              }} page={'Careers'} tag="detail-page" category={`Careers`} name={GQLPage.JobTitle} />
             <CareerContent data={GQLPage} />
             <FooterBlock { ...GQLPage?.Footer_Block} id={GQLPage?.id} imagename="careers.Modules.Footer_Block.Image.tile_image" />
             {/* <NewsLetter /> */}
              <Footer popularSearch={GQLPage.Popular_Search} />
              {GQLPage.Show_CTA_Sticky_Mobile && 
              <StickyFooter cta_1={GQLPage.Header_CTA_1_Label}
                          cta_2={GQLPage.Header_CTA_2_Label}
                          cta_1_link={GQLPage.Header_CTA_1_Link}
                          cta_2_link={GQLPage.Header_CTA_2_Link}
                          search={GQLPage.Show_Search_Type} />
              }
        </div>
      </div>
    )
  }
  
  export default CareerDetails
  
  export const pageQuery = graphql`
    query careerQuery($id: ID!) {
        glstrapi {
          career(id: $id, publicationState: LIVE) {
                id
                JobTitle
                URL
                Meta_Title
                Meta_Description
                Location
                Department
                Salary
                JobDescription
                imagetransforms
                Footer_Block {
                    FooterBlockTitle: Title
                    Content
                    CTA_1_Label
                    CTA_1_Action
                    CTA_1_Link {
                    id
                    URL
                    }
                    CTA_2_Label
                    CTA_2_Action
                    CTA_2_Link {
                    id
                    URL
                    }
                    Image {
                    url
                    alternativeText
                    }
                }
                ContactPerson {
                    id
                    Name
                    URL
                    Designation
                    Email
                    Tile_Image {
                    url
                    alternativeText
                    }
                }
                    
          }           
        }
    }
  `