import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { Container, Row, Col } from 'react-bootstrap';
import CareerContentSidebar from './CareerContentSidebar';
import "./CareerContent.scss";

const CareerContent = ({ data }) => {
  const { JobTitle, Location, Department, Salary, JobDescription, ContactPerson } = data || {};

  return (
    <div className="career-content-wrap sec-pdy-80">
      <div className="career-header">
        <Container>
          {JobTitle && <h1 className="h2">{JobTitle}</h1>}
          {Location && (
            <p className="text-sm">
              <span className="text-sm-bold">Location:</span> <span>{Location}</span>
            </p>
          )}
          {Department && (
             <p className="text-sm">
              <span className="text-sm-bold">Department:</span> <span>{Department}</span>
            </p>
          )}
          {Salary && (
             <p className="text-sm">
              <span className="text-sm-bold">Salary:</span> <span>{Salary}</span>
            </p>
          )}
        </Container>
      </div>
      <div className="career-page-static-content">
      <Container>
        <Row>
          <Col lg={7} xl={8} className="plain-content">
            <div className="left-content">
              {JobDescription ? HTMLReactParser(JobDescription.replace(/<br\s*\/?>/gi, '')) : null}
            </div>
          </Col>

          <Col lg={5} xl={4} className="sidebar-content">
            <CareerContentSidebar people={ContactPerson} />
          </Col>
        </Row>
      </Container>
      </div>
    </div>
  );
};

export default CareerContent;