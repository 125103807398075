import { Link } from "@StarberryUtils";
import React, { useEffect, useState, Fragment } from "react";
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
import { GetURL } from "../../common/site/functions";
import ModalTiny from "react-bootstrap/Modal";
import BookAViewForm from "../../forms/book-a-viewing-form";
import ValuationFormPage from "../../forms/valuation-form";
import CareerForm from "../../forms/career-form";
import MortgageForm from "../../forms/mortgage-form";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import useDeviceMedia from "../../utils/useDeviceMedia";
import { CloseIcon } from "../../Icons/Icons";
import $ from 'jquery';
import "./Contact.scss";

// markup
const Contact = (props) => {
    const { isCustomMin } = useDeviceMedia({ min: "992px" });
    const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false);
    useEffect(() => {
        $(".banner_search").click(function () {
            $('html, body').animate({
                scrollTop: $("#main-banner").offset().top - 10
            }, 1000);
        });
    }, []);

    const ctaAction = (type, label, action) => {
        var cls = "btn btn-primary";
        if (type === 2) {
            cls = "btn btn-secondary";
        }
        if (action === "Banner_Search") {
            return <Button className={`${cls} banner_search`}>{label}</Button>
        } else if (action === "Request_Callback") {
            return <a href="javascript:void(0)" className={`${cls} max-width-high`} onClick={openBookViewformModal}>{label}</a>
        } else if (action === "Request_Valuation") {
            return <a href="javascript:void(0)" className={`${cls} max-width-high`} onClick={openValuationformModal}>{label}</a>
        } else if (action === "Send_CV") {
            return <a href="javascript:void(0)" className={`${cls} max-width-high`} onClick={openApplyformModal}>{label}</a>
        }else if (action === "Mortgage_Form") {
            return <a href="javascript:void(0)" className={`${cls} max-width-high`} onClick={openMortgageFormModal}>{label}</a>
        }
    };

    const ctaLink = (type, label, link) => {
        var cls = "btn btn-primary";
        if (type === 2) {
            cls = "btn btn-secondary";
        }
        if (link?.Link_Type === "Internal") {
            return <a href={`/${GetURL(link.id)}`} className={cls}>{label}</a>
        } else if (link?.Link_Type === "External") {
            return <a href={`${GetURL(link.id)}`} target="_blank" className={cls}>{label}</a>
        }else {
            return <a href={`/${GetURL(link?.id)}`} className={cls}>{label}</a>
        }
    };

    const openBookViewformModal = () => {
        setBookViewingformOpen(true);
    };
    const closeBookViewformModal = () => {
        setBookViewingformOpen(false);
    };

    const [modalValuationformOpen, setValuationformOpen] = React.useState(false);
    const openValuationformModal = () => {
        setValuationformOpen(true);
    };
    const closeValuationformModal = () => {
        setValuationformOpen(false);
    };

    const [modalApplyformOpen, setApplyformOpen] = React.useState(false);

	const openApplyformModal = () => {
		setApplyformOpen(true);
	}
	const closeApplyformModal = () => {
		setApplyformOpen(false);
	}

	const [modalMortgageForm, setMortgageForm] = React.useState(false);

	const openMortgageFormModal = () => {
		setMortgageForm(true);
	}
	const closeMortgageFormModal = () => {
		setMortgageForm(false);
	}


    const image_url = props?.Image?.url

    let processedImages = JSON.stringify({});
    if (props.imagetransforms?.Modules?.Footer_Block?.Image_Transforms) {
      processedImages = props.imagetransforms.Modules.Footer_Block.Image_Transforms;
    }
  
    return (
        <Fragment>
            <div className="footer-block contact">
                <Container fluid={isCustomMin}>
                    <Row>
                        <Col lg={7} className="d-flex px-lg-0 block-left">
                            <div className="contact-us">
                                 {props.FooterBlockTitle && <h4>{props.FooterBlockTitle}</h4>}
                                 {props.Content &&
                                    <div className="footer-block-text text-sm">{HTMLReactParser(props.Content.replace(/<br\s*\/?>/gi, ''))}</div>
                                 }
                                <div className="btn-wrapper">
                                    {props.CTA_1_Label && props.CTA_1_Link &&
                                        ctaLink(1, props.CTA_1_Label, props.CTA_1_Link)
                                    }
                                    {props.CTA_1_Label && props.CTA_1_Action &&
                                        ctaAction(1, props.CTA_1_Label, props.CTA_1_Action)
                                    }
                                    {props.CTA_2_Label && props.CTA_2_Link &&
                                        ctaLink(2, props.CTA_2_Label, props.CTA_2_Link)
                                    }
                                    {props.CTA_2_Label && props.CTA_2_Action &&
                                        ctaAction(2, props.CTA_2_Label, props.CTA_2_Action)
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={5} className="p-0 d-none d-lg-flex block-right">
                            <div className="block-image">
                                <ImageTransform imagesources={image_url} renderer="srcSet" imagename={props?.imagename ? props?.imagename : "articles.Modules.Footer_Block.Image.tile_image" } attr={{ alt: `${props?.Image?.alternativeText} - Dacha` }} imagetransformresult={processedImages} id={props.id} />                     
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ModalTiny show={modalBookViewformOpen} onHide={closeBookViewformModal} className="modal-form-wrapper form-viewing-wrapper">
				<ModalTiny.Header>
				<div class="modal-close-btn" onClick={closeBookViewformModal}><CloseIcon /></div>
				<ModalTiny.Title>
					<h4>Book an Appointment</h4>
				</ModalTiny.Title>
				</ModalTiny.Header>
				<ModalTiny.Body>
				<BookAViewForm
					property_img={``}
					property_id={''}
					page_url={null}
					property_title={''}
					negotiator={''} />
				</ModalTiny.Body>
			</ModalTiny>    
			<ModalTiny show={modalValuationformOpen} onHide={closeValuationformModal} className="modal-form-wrapper form-valuation-wrapper">      
				<ModalTiny.Header>  
				<div class="modal-close-btn" onClick={closeValuationformModal}><CloseIcon /></div>        
				<ModalTiny.Title>
					<h4>Property Valuation</h4>
					<p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nec risus nec risus convallis aliquet lipsum.</p>
				</ModalTiny.Title>
				</ModalTiny.Header>
				<ModalTiny.Body>
				<ValuationFormPage />
				</ModalTiny.Body>
			</ModalTiny>
            <ModalTiny show={modalApplyformOpen} onHide={closeApplyformModal} className="modal-form-wrapper people-details-form-modal">
                <ModalTiny.Header>
                <div class="modal-close-btn" onClick={closeApplyformModal}><CloseIcon /></div>
                    <ModalTiny.Title><h4>Send your CV</h4></ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body>
                    <CareerForm />
                </ModalTiny.Body>
                </ModalTiny>
                <ModalTiny show={modalMortgageForm} onHide={closeMortgageFormModal} className="modal-form-wrapper people-details-form-modal">
                <ModalTiny.Header>
                <div class="modal-close-btn" onClick={closeMortgageFormModal}><CloseIcon /></div>
                    <ModalTiny.Title><h4>Mortgage Consulting</h4></ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body>
                    <MortgageForm />
                </ModalTiny.Body>
            </ModalTiny>
        </Fragment>
    );
};

export default Contact;